import React, { useEffect, useRef, useState } from "react";
import "./App.css";
import * as THREE from "three";
import WAVES from "vanta/dist/vanta.waves.min";
import image from "./dseas.png";
import image2 from "./forweb.PNG";

function App() {
  const [vantaEffect, setVantaEffect] = useState(null);
  const [titleText, setTitleText] = useState("Join our Web3 Voyage.");
  const [fade, setFade] = useState(false);
  const myRef = useRef(null);

  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(
        WAVES({
          el: myRef.current,
          THREE: THREE,
          mouseControls: true,
          touchControls: true,
          minHeight: window.innerHeight,
          minWidth: window.innerWidth,
          scale: 1.0,
          scaleMobile: 1.0,
          color: 0x123456,
          shininess: 70,
          waveHeight: 44.0,
          waveSpeed: 0.5,
          zoom: 0.65
        })
      );
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, [vantaEffect]);

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(true);
      setTimeout(() => {
        setTitleText((prevText) =>
          prevText === "Join our Web3 Voyage."
            ? "Discover Decentralized Gaming."
            : "Join our Web3 Voyage."
        );
        setFade(false);
      }, 1500);
    }, 5300);
    return () => clearInterval(interval);
  }, []);

  const scrollToNextScreen = () => {
    const nextScreen = document.getElementById("next-screen");
    if (nextScreen) {
      nextScreen.scrollIntoView({ behavior: "smooth" });
    }
  };
  const sendEmail = () => {
    const email = "mark@devseas.com";
    const subject = "Work with us inquiry";
    const body = `Hello,
  
  I am interested in working with you.
  
  Best regards,
  [Your Name]`;

    const mailto = `mailto:${email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.location.href = mailto;
  };

  return (
    <div className="container">
      <div ref={myRef} className="App">
        <div className="logo-container">
          <img src={image} height={120} />
          <h1 className="title-text" onClick={scrollToNextScreen}>
            <span className={`fade-text ${fade ? "fade-out" : "fade-in"}`}>
              {titleText}
            </span>
          </h1>
        </div>
      </div>

      <div id="next-screen">
        <div>
          <h2>We build world class products for the modern era.</h2>
        </div>
        <div>
          <p className="text">
            There is a blue ocean 🌊, full of opportunity 💰 in the Web3 gaming
            space.
          </p>
          <p className="text">
            We are starting with online gambling casino platforms accessible to
            all regions of the world.
          </p>
        </div>
        <div></div>
        <div className="grey-section">
          <div className="blockbluff-container">
            <div>
              <p className="block-text">BlockBluff.com</p>
              <p className="text-small">
                A fully featured crypto poker app in the browser.
              </p>
            </div>
            <img src={image2} height={420} />
          </div>
        </div>
        <button className="work-with-us-btn" onClick={sendEmail}>
          Work with us
        </button>
        <p className="footer-text">
          Based in America's crypto capital Miami, FL
        </p>
        <p className="footer-text">mark@devseas.com </p>
      </div>
    </div>
  );
}

export default App;
